import { useMemo } from 'react';

import { sumBy } from 'lodash';
import { CollectionQueryOptions, Nullable } from '../types';
import { Company, Quote, SanitizedQuoteConversionRate } from '../models';
import { ProductType, PurchaseStatus, QuoteStatus } from '../enums';
import { CurrencyFormatter } from '../utils';
import { getQuoteProductExtendedPrice } from '../utils/quote';
import { getCompanyLocation } from '../utils/company';
import { useDataCollection } from './useDataCollection';

export function useQuoteCompanyConversionRates(
  quotes: Quote[],
  companyIds?: Nullable<string[]>,
  productTypes?: Nullable<ProductType[]>,
  options?: Pick<CollectionQueryOptions, 'pagination' | 'sorting'>
) {
  const quoteConversionRates = useMemo(() => {
    const result = quotes.reduce((acc, cur) => {
      const companyId = cur.companyId;
      const filteredProducts = cur.products?.filter(
        (p) =>
          !productTypes ||
          (p.productType && productTypes.includes(p.productType))
      );

      if (
        !companyId ||
        !cur.companyName ||
        !filteredProducts?.length ||
        (companyIds && !companyIds.includes(companyId))
      ) {
        return acc;
      }

      const companyLocation = getCompanyLocation({
        accountNumber: cur.companyAccountNumber,
      } as Company);
      const currencyFormatter = new CurrencyFormatter(
        companyLocation,
        Number.MAX_VALUE
      );

      const record = acc[companyId] || new SanitizedQuoteConversionRate();

      if (!record.id || !record.name) {
        record.id = companyId;
        record.name = cur.companyName;
      }

      record.totalQuoted += sumBy(filteredProducts, (p) =>
        getQuoteProductExtendedPrice(p, cur.measurementUnits, cur.priceUnits)
      );

      record.quoted += filteredProducts.length;

      if (cur.status === QuoteStatus.Expired) {
        record.expired += filteredProducts.length;
      } else {
        filteredProducts.forEach((p) => {
          if (p.purchaseStatus === PurchaseStatus.Lost) {
            record.lost++;
          } else if (p.purchaseStatus === PurchaseStatus.Won) {
            record.won++;
            record.totalWon += getQuoteProductExtendedPrice(
              p,
              cur.measurementUnits,
              cur.priceUnits
            );
          }
        });
      }

      record.rate = record.quoted
        ? Math.round((record.won / record.quoted) * 100)
        : 0;

      record.totalWonRate =
        record.totalWon && record.totalQuoted
          ? Math.round((record.totalWon / record.totalQuoted) * 100)
          : 0;

      record.totalQuotedFormatted = currencyFormatter.format(
        record.totalQuoted
      );
      record.totalWonFormatted = currencyFormatter.format(record.totalWon);

      acc[companyId] = record;
      return acc;
    }, {} as Record<string, SanitizedQuoteConversionRate>);

    return Object.values(result);
  }, [companyIds, productTypes, quotes]);

  return useDataCollection(
    quoteConversionRates,
    options?.sorting,
    options?.pagination
  );
}

import { t } from 'i18next';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { DashboardCard } from '../../../components';
import {
  useAgencyOutstandingQuoteRequests,
  usePermission,
} from '../../../hooks';
import { UserPermission } from '../../../enums';
import * as Sx from './Dashboard.styles';

export const AgencyOutstandingQuoteRequestsCard: React.FC = () => {
  const { hasAllPermissions } = usePermission();
  const { list: quotes } = useAgencyOutstandingQuoteRequests(
    !hasAllPermissions([
      UserPermission.RequestQuotes,
      UserPermission.ViewAgencyQuotes,
    ])
  );

  return (
    <DashboardCard
      icon={<RequestQuoteIcon sx={Sx.icon} />}
      link="/agent/quotes"
      linkText={t('dashboard.quotes')}
      message={quotes?.length?.toString() ?? 0}
      title={t('dashboard.quoteRequests')}
    />
  );
};

import { where } from 'firebase/firestore';

import { Quote } from '../models';
import { QuoteStatus } from '../enums';
import { useCollection } from './useCollection';
import { useCurrentUser } from '.';

export function useAgencyOutstandingQuoteRequests(
  currentUserQuotesOnly = false
) {
  const user = useCurrentUser();
  return useCollection<Quote>('Quotes', [
    where('status', 'in', [
      QuoteStatus.Draft,
      QuoteStatus.InProgress,
      QuoteStatus.Submitted,
    ]),
    currentUserQuotesOnly
      ? where('customerId', '==', user.id)
      : where('agencyId', '==', user.companyRef.id),
  ]);
}

import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  ListItemText,
  MenuItem,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DataTable, DataTableColumn } from '../../tables';
import {
  CustomLeadTimeRowCollapse,
  FormattedTimestamp,
  MeasurementUnitsToggle,
} from '..';
import {
  useCurrentUser,
  useFormFieldValue,
  useQuoteDetails,
  useQuoteFields,
  useQuoteMarkets,
} from '../../hooks';
import { Quote, QuoteProduct } from '../../models';
import { MeasurementUnits, QuoteStatus } from '../../enums';
import { NullableOutput } from '../../tables/components/NullableOutput';
import {
  CatalogFiltersType,
  CatalogSearchFilters,
} from '../CatalogSearchDialog/CatalogFiltersType';
import { Inventory } from '../../types/Inventory';
import { convertQuoteProductQuantities } from '../../utils/quote';
import { QuoteProductsTableProps } from './QuoteProductsTable.props';
import * as Sx from './QuoteProductsTable.styles';
import { QuoteProductsTableCatalog } from './QuoteProductsTableCatalog.component';
import { QuoteProductPartNumber } from './Components';

export const QuoteProductsTable = memo(
  ({ company, form, units, priceUnits, setUnits }: QuoteProductsTableProps) => {
    const { t } = useTranslation();
    const user = useCurrentUser();
    const { isReadonly, quoteIsPriced } = useQuoteDetails(form);

    const isMobile = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down('md')
    );

    const { canSeeCSA, canSeeUL, isValid } = useQuoteMarkets(form);

    const { value: companyId } = useFormFieldValue<Quote, string>(
      form,
      'companyId'
    );

    const { value: products, setValue: setProducts } = useFormFieldValue<
      Quote,
      Array<QuoteProduct>
    >(form, 'products');

    const { value: status } = useFormFieldValue<Quote, QuoteStatus>(
      form,
      'status'
    );

    const {
      getDescription,
      getDiscount,
      getExtendedPrice,
      getLeadTime,
      getMinimumOrderQuantity,
      getNetPrice,
      getPrice,
      getQuantity,
      getColors,
    } = useQuoteFields(form, company);

    const [catalogFilters, setCatalogFilters] = useState<CatalogSearchFilters>({
      filters: null,
      inventory: canSeeCSA ? 'csa' : 'ul',
    });

    const onCatalogFiltersChange = (
      filters: CatalogFiltersType,
      inventory: Inventory
    ) => {
      setCatalogFilters({ filters, inventory: inventory });
    };

    const verifyCatalogFilters = () => {
      if (!canSeeCSA && catalogFilters.inventory === 'csa') {
        setCatalogFilters({ filters: null, inventory: 'ul' });
      } else if (!canSeeUL && catalogFilters.inventory === 'ul') {
        setCatalogFilters({ filters: null, inventory: 'csa' });
      }
    };

    const onUnitsChangeHandler = (value: MeasurementUnits) => {
      setUnits((prev) => (value ? (prev = value) : prev));
      if (value && value !== units && !isReadonly) {
        setProducts(convertQuoteProductQuantities(products, value));
      }
    };

    const removeProduct = (product: QuoteProduct) =>
      setProducts(
        products
          .filter((p) => p.id !== product.id)
          .map((p) =>
            p.lineItem > product.lineItem
              ? { ...p, lineItem: p.lineItem - 1 }
              : p
          )
      );

    const menuItems = (product: QuoteProduct) => (
      <MenuItem onClick={() => removeProduct(product)}>
        <ListItemText>
          {t('quotes.edit.products.table.actions.delete')}
        </ListItemText>
      </MenuItem>
    );

    const commonStyling: SxProps = {
      my: {
        xs: 'auto',
      },
    };

    const displayCustomFirstRow = useMemo(
      () => !!companyId && (!status || status === QuoteStatus.Draft),
      [companyId, status]
    );

    const getFirstRow = () =>
      displayCustomFirstRow ? (
        <QuoteProductsTableCatalog
          setUnits={onUnitsChangeHandler}
          units={units}
          form={form}
          catalogFilters={catalogFilters}
          onCatalogFiltersChange={onCatalogFiltersChange}
          verifyCatalogFilters={verifyCatalogFilters}
        />
      ) : undefined;

    return (
      <Grid container direction="column" justifyContent="flex-start">
        <Grid
          item
          container
          my={1}
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <Typography variant="h3">
            {t('quotes.edit.products.header', {
              count: form.item?.products?.length ?? 0,
            })}
          </Typography>
          <Grid item justifyContent="center" display="flex">
            <MeasurementUnitsToggle
              units={units}
              setUnits={onUnitsChangeHandler}
            />
          </Grid>
        </Grid>
        <DataTable
          menuItems={isReadonly ? undefined : menuItems}
          data={products || []}
          noResultElement={
            <Typography variant="h4" sx={{ my: 4 }} align={'center'}>
              {t('quotes.edit.products.noResults')}
            </Typography>
          }
          rowCollapseOpened={(product) =>
            status === QuoteStatus.Quoted &&
            !!product.customLeadTime &&
            !isMobile
          }
          rowCollapseContent={(product: QuoteProduct) => (
            <CustomLeadTimeRowCollapse mode="view" product={product} />
          )}
          tableStyles={Sx.tableStyles}
          tableBodyStyles={Sx.tableBodyStyles(displayCustomFirstRow)}
          tableHeaderStyles={Sx.tableHeaderStyles}
          specialFirstBodyRow={getFirstRow()}
          isRowOnError={(row) => !isValid(row)}
          customRowKey={(row: QuoteProduct) => row.id + row.lineItem}
        >
          <DataTableColumn
            mobileOrder={-1}
            property="lineItem"
            title={t('quotes.edit.products.table.labels.lineItem')}
            otherStyling={commonStyling}
          />
          <DataTableColumn
            mobileOrder={0}
            property="partNumber"
            title={t('quotes.edit.products.table.labels.partNumber')}
            otherStyling={commonStyling}
            output={(_, row: QuoteProduct) => (
              <QuoteProductPartNumber
                row={row}
                isValid={isValid}
                status={status}
              />
            )}
          />
          <DataTableColumn
            mobileOrder={1}
            mobileWidth="100%"
            property="productDescription"
            title={t('quotes.edit.products.table.labels.productDescription')}
            otherStyling={commonStyling}
            output={(_, row: QuoteProduct) => {
              const descriptionElement = getDescription(row);
              const colorsElement = getColors(row);

              return (
                <>
                  {descriptionElement}
                  {colorsElement !== 'null' && <> - {colorsElement}</>}
                </>
              );
            }}
          />
          <DataTableColumn
            mobileOrder={2}
            mobileWidth={quoteIsPriced ? '65%' : '50%'}
            property="quantity"
            title={t('quotes.edit.products.table.labels.quantity')}
            output={(_, row: QuoteProduct) =>
              getQuantity(row, units, isReadonly)
            }
          />

          <DataTableColumn
            mobileOrder={3}
            mobileWidth="35%"
            mobileHidden={!quoteIsPriced}
            property="minimumOrderQuantity"
            title={t('quotes.edit.products.table.labels.minOrderQuantity')}
            tooltip={t('quotes.edit.products.table.tooltips.minOrderQuantity')}
            output={(_, row: QuoteProduct) =>
              getMinimumOrderQuantity(row, quoteIsPriced, units)
            }
          />
          <DataTableColumn
            mobileOrder={4}
            mobileWidth="35%"
            mobileHidden={!quoteIsPriced}
            property="price"
            title={t('quotes.edit.products.table.labels.price')}
            output={(_, row: QuoteProduct) =>
              getPrice(row, quoteIsPriced, priceUnits)
            }
          />
          <DataTableColumn
            mobileOrder={5}
            mobileWidth="30%"
            mobileHidden={!quoteIsPriced}
            property="discount"
            title={t('quotes.edit.products.table.labels.discount')}
            output={(_, row: QuoteProduct) => getDiscount(row, quoteIsPriced)}
          />
          <DataTableColumn
            mobileOrder={6}
            mobileWidth="35%"
            mobileHidden={!quoteIsPriced}
            property="netPrice"
            title={t('quotes.edit.products.table.labels.netPrice')}
            output={(_, row: QuoteProduct) =>
              NullableOutput<QuoteProduct>(
                row,
                'price',
                (row) => getNetPrice(row),
                true,
                !quoteIsPriced
              )
            }
          />
          <DataTableColumn
            mobileOrder={7}
            mobileWidth="65%"
            mobileHidden={!quoteIsPriced}
            property="extendedPrice"
            title={t('quotes.edit.products.table.labels.extendedPrice')}
            output={(_, row: QuoteProduct) =>
              NullableOutput<QuoteProduct>(
                row,
                'price',
                (row) =>
                  getExtendedPrice(
                    row,
                    isReadonly && form.item?.measurementUnits
                      ? form.item?.measurementUnits
                      : units,
                    priceUnits
                  ),
                true,
                !quoteIsPriced
              )
            }
          />
          <DataTableColumn
            mobileOrder={8}
            mobileWidth="35%"
            property="standardLeadTime"
            title={t('quotes.edit.products.table.labels.leadTime')}
            otherStyling={{
              opacity: 0.6,
            }}
            output={(_, row: QuoteProduct) => getLeadTime(row, status)}
          />
          <DataTableColumn
            hidden
            mobileOrder={9}
            mobileHidden={!quoteIsPriced}
            property="customLeadTime"
          />
        </DataTable>

        {form.item?.quotedTime && (
          <Sx.StyledBox>
            <Typography>
              {t('quotes.edit.summary.quoteDetails.leadTimesAsOf')}
              <FormattedTimestamp
                timestamp={form.item?.quotedTime}
                timeZone={user.timeZone}
              />
            </Typography>
          </Sx.StyledBox>
        )}
      </Grid>
    );
  }
);

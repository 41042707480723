import { useMemo, useState } from 'react';
import { Button, IconButton, TableCell } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTranslation } from 'react-i18next';
import { CatalogSearchDialog, Show } from '..';
import {
  useAuthentication,
  useFormFieldValue,
  useQuoteDetails,
  useQuoteMarkets,
} from '../../hooks';
import { Cable, Quote, QuoteProduct } from '../../models';
import { StyledTableRow } from '../../tables';
import { convertQuoteProductPackageLength } from '../../utils/quote';
import { Language, MeasurementUnits, StandardType } from '../../enums';
import { QuoteProductsTableCatalogProps } from './QuoteProductsTableCatalog.props';
import { CUSTOM_PART_NUMBER } from './constants';

export const QuoteProductsTableCatalog = ({
  form,
  units,
  setUnits,
  catalogFilters,
  onCatalogFiltersChange,
  verifyCatalogFilters,
  priceUnits,
  setPriceUnits,
}: QuoteProductsTableCatalogProps) => {
  const { t } = useTranslation();
  const { isReadonly } = useQuoteDetails(form);
  const { isAdmin } = useAuthentication();

  const [catalogSearchDialogOpen, setCatalogSearchDialogOpen] = useState(false);

  const onCatalogSearchOpen = () => {
    verifyCatalogFilters();
    setCatalogSearchDialogOpen(true);
  };

  const { value: products, setValue: setProducts } = useFormFieldValue<
    Quote,
    Array<QuoteProduct>
  >(form, 'products');

  const { canSeeCSA, canSeeUL } = useQuoteMarkets(form);

  const canSeeMarket = useMemo(
    () => Boolean(canSeeCSA || canSeeUL),
    [canSeeCSA, canSeeUL]
  );

  const addProductToQuote = (product: Cable, quantity: number) => {
    if (!products?.find((p) => p.partNumber === product.id)) {
      const newProduct: QuoteProduct = {
        ...product,
        lineItem: (products?.length || 0) + 1,
        minimumOrderQuantity: convertQuoteProductPackageLength(
          product.packageLength || 0,
          units
        ),
        price: 0,
        discount: 0,
        partNumber: product.id,
        quantity,
        customLeadTime: null,
      };

      setProducts([...(products || []), newProduct]);
    }
  };

  const addCustomProductToQuote = () => {
    const customProduct: QuoteProduct = {
      id: CUSTOM_PART_NUMBER,
      description: '',
      discount: 0,
      isCSA: true,
      isUL: true,
      lineItem: (products?.length || 0) + 1,
      materialType: null,
      minimumOrderQuantity: 0,
      normalizedId: CUSTOM_PART_NUMBER,
      partNumber: CUSTOM_PART_NUMBER,
      price: 0,
      productType: null,
      quantity: 0,
      standardLeadTime: {
        [Language.English]: '',
        [Language.French]: '',
      },
      standardType: StandardType.Unspecified,
    };

    setProducts([...(products || []), customProduct]);
  };

  const updateProductFromQuote = (product: Cable, quantity: number) => {
    const updatedProducts =
      products?.map((p) =>
        p.partNumber === product.id ? { ...p, quantity } : p
      ) || [];

    setProducts(updatedProducts);
  };

  const toggleUnitPriceUnits = () => {
    setPriceUnits?.(
      priceUnits === MeasurementUnits.Imperial
        ? MeasurementUnits.Metric
        : MeasurementUnits.Imperial
    );
  };

  return (
    <>
      <Show if={canSeeMarket && !isReadonly}>
        <StyledTableRow
          sx={{ borderTop: { xs: 'none' } }}
          id="custom-first-row"
        >
          {!isAdmin && (
            <TableCell sx={{ p: 0.5 }}>
              <IconButton
                sx={{ p: 0.5 }}
                size="large"
                color="secondary"
                onClick={onCatalogSearchOpen}
              >
                <AddCircleIcon fontSize="inherit" />
              </IconButton>
            </TableCell>
          )}
          {isAdmin && (
            <>
              <TableCell sx={{ p: 0.5 }} colSpan={3}>
                <Button
                  startIcon={<AddCircleIcon />}
                  color="secondary"
                  onClick={onCatalogSearchOpen}
                  sx={{ width: 'max-content' }}
                >
                  {t('quotes.edit.products.table.actions.addFromCatalog')}
                </Button>
                <Button
                  startIcon={<LibraryAddIcon />}
                  onClick={addCustomProductToQuote}
                  sx={{ width: 'max-content' }}
                >
                  {t('quotes.edit.products.table.actions.addCustomProduct')}
                </Button>
              </TableCell>
              <TableCell colSpan={2} />
              <TableCell sx={{ p: 0.5 }} colSpan={3}>
                <Button
                  sx={{ pl: 2 }}
                  startIcon={<RefreshIcon />}
                  onClick={toggleUnitPriceUnits}
                >
                  {t('quotes.edit.products.table.actions.toggleUnits')}
                </Button>
              </TableCell>
            </>
          )}
        </StyledTableRow>
      </Show>
      {canSeeMarket && (
        <CatalogSearchDialog
          units={units}
          setUnits={setUnits}
          form={form}
          onAddProduct={addProductToQuote}
          onUpdateProduct={updateProductFromQuote}
          catalogSearchDialogOpen={catalogSearchDialogOpen}
          setCatalogSearchDialogOpen={setCatalogSearchDialogOpen}
          catalogFilters={catalogFilters}
          onCatalogFiltersChange={onCatalogFiltersChange}
        />
      )}
    </>
  );
};

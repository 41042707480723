import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { AnyObjectSchema, ValidationError } from 'yup';
import { isEqual } from 'lodash';

import { usePrompt } from '../navigation/ReactRouterHooks';
import { Formable, Nullable, Persistable } from '../types';
import { ModificationMode, NotificationLevel } from '../enums';

import { useNotification, useProgress } from './';

/**
 * Configuration options for useForm hook
 */
type UseFormProps<T> = {
  /** Set the mode for the form, using `Edit` by default  */
  mode?: ModificationMode;
  /** Validation schema for object */
  validations?: AnyObjectSchema;
  /** Default value for the object will be used, if it does not exist */
  defaultValue?: T;
  /** Callback to modify object before it is saved */
  beforeSave?: (item: T, skipValidation: boolean) => void | Promise<void>;
  /** Callback when item when revert has been requested */
  onRevert?: () => boolean;
  /** Callback when item has been successfully saved */
  onSaveSuccess?: (
    state: T,
    meta: { skipValidation: boolean; previousState: T }
  ) => void;
  /** Enable or disable the prompt that occurs when navigating away from page; enabled by default */
  defaultNavigationPrompt?: boolean;
};

interface CallbackItem<T> {
  previousState: T;
  skipValidation: boolean;
  state: T;
}

type FieldRefs = {
  [k: string]: RefObject<HTMLInputElement>;
};

function useFieldsRef() {
  const fields = useRef<FieldRefs>({});

  return React.useCallback(
    (fieldName: string) => (fields.current[fieldName] ??= React.createRef()),
    [fields]
  );
}

/**
 * Creates a form to be used to be form components and which can be saved or reverted.
 *
 * @param persistable - Object that will be edited on the form
 * @param options - Configuration for the form
 */
export function useForm<T>(
  persistable: Persistable<T>,
  options?: UseFormProps<T>
): Formable<T> {
  const mode = options?.mode ?? ModificationMode.Edit;

  const fieldRef = useFieldsRef();
  const { t } = useTranslation();
  const [withPrompt, setWithPrompt] = useState(
    options?.defaultNavigationPrompt ?? true
  );
  const { addNotification, clearNotification } = useNotification();
  const { showProgress } = useProgress();
  const [hideProgress, setHideProgress] = useState(false);
  const [defaultValues, setDefaultValues] = useState<T | null>(
    persistable.item || options?.defaultValue || null
  );
  const [item, setItemInternally] = useState<T | null>(defaultValues);
  const [errors, setErrors] = useState({} as Record<keyof T, string>);
  const [dirty, setDirty] = useState<boolean>(false);
  const [callbackToCall, setCallbackToCall] = useState<'onSaveSuccess' | null>(
    null
  );
  const [callbackItem, setCallbackItem] =
    useState<Nullable<CallbackItem<T>>>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [shouldValidate, setShouldValidate] = useState<boolean>(
    mode !== ModificationMode.Add
  );

  useEffect(() => {
    if (!hideProgress) {
      showProgress(saving);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving]);

  useEffect(() => {
    if (persistable.item) {
      setDefaultValues(persistable.item);
    }
  }, [persistable.item]);

  useEffect(() => {
    if (!dirty) {
      setItemInternally(defaultValues);
    }
  }, [defaultValues, dirty]);

  // Call the requested callback; this is done via a useEffects to call on
  // a new render cycle to allow the withPrompt to be disabled, as the
  // onSaveSuccess callbacks often navigate to a new page, which would trigger
  // the withPrompt prompt
  useEffect(() => {
    if (callbackToCall) {
      if (
        callbackItem &&
        options?.onSaveSuccess &&
        callbackToCall === 'onSaveSuccess'
      ) {
        const { state, ...meta } = callbackItem;
        options.onSaveSuccess(state, meta);
        setCallbackToCall(null);
        setCallbackItem(null);
      }
    }
    // Ignore options, as it never should change, but regenerated
    // every time function is called, therefore:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackToCall, callbackItem]);

  // Create a list of errors based on validation schema provided (if any)
  const validate = useCallback(
    async (
      itemToValidate: T | null,
      tryFocus = false,
      shouldSetErrors = false,
      validationSchema?: AnyObjectSchema
    ) => {
      const newErrors = {} as Record<keyof T, string>;
      const validations = validationSchema || options?.validations;
      if (itemToValidate && validations) {
        await validations
          .validate(itemToValidate, {
            abortEarly: false,
          })
          .catch((e) => {
            const error = e as ValidationError;

            error.inner?.forEach((field, index) => {
              if (tryFocus && index < 1) {
                fieldRef(field.path as string).current?.focus();
              }
              if (typeof field.errors[0] === 'string') {
                newErrors[field.path as keyof T] = t(field.errors[0]);
              } else {
                const { key, values } = field.errors[0] as unknown as {
                  key: string;
                  values: Record<string, string>;
                };

                newErrors[field.path as keyof T] = t(key, {
                  ...values,
                  defaultValue: '',
                });
              }
            });
          });
      }

      if (shouldSetErrors) {
        setErrors(newErrors);
      }

      return newErrors;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options?.validations, t]
  );

  useEffect(() => {
    setShouldValidate(mode !== ModificationMode.Add);
  }, [mode]);

  // Check validations and save list of errors, if applicable
  useEffect(() => {
    if (shouldValidate) {
      validate(item).then((validationErrors) => {
        setErrors(validationErrors);
      });
    }
  }, [item, shouldValidate, validate]);

  const setItem = useCallback(
    (data: T, dirty = true) => {
      if (!isEqual(data, item)) {
        setDirty(dirty);
        setItemInternally(() => data);
      }
    },
    [item]
  );

  const valid = useMemo(
    () => (item && (!errors || Object.keys(errors).length === 0)) || false,
    [errors, item]
  );

  const save = async (skipValidation = false, hideProgress = false) => {
    setHideProgress(hideProgress);
    if (item && (valid || skipValidation)) {
      // Start saving process
      addNotification(t('forms.saving'), NotificationLevel.Information);
      setSaving(true);

      // If should validate is false (in the case of adding a new item to avoid having
      // all the errors display at the start), we need to validate now and abort if there
      // are errors.
      if (!shouldValidate && !skipValidation) {
        const validationErrors = await validate(item, true);
        setShouldValidate(true);

        if (validationErrors && Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          setSaving(false);
          addNotification(
            t('forms.savedUnsuccessfullyDueToValidation'),
            NotificationLevel.Error
          );
          return;
        }
      }

      const previousState = persistable.item as T;

      // Call callback if exists
      if (options?.beforeSave) {
        await options.beforeSave(item, skipValidation);
      }

      try {
        const savedItem = await persistable.save(item);
        addNotification(
          t('forms.savedSuccessfully'),
          NotificationLevel.Success
        );
        setDefaultValues(savedItem);
        setSaving(false);
        setDirty(false);
        setCallbackItem({ state: savedItem, skipValidation, previousState });
        setCallbackToCall('onSaveSuccess');

        // Catch clause typing is restricted to any or unknown, therefore
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        console.error(e);
        addNotification(
          t(e.name === 'CustomError' ? e.message : 'forms.savedUnsuccessfully'),
          NotificationLevel.Error
        );
      }

      setSaving(false);
    }
  };

  const revert = () => {
    if (options?.onRevert) {
      const result = options.onRevert();
      if (!result) {
        return;
      }
    }

    setDirty(false);
    clearNotification();
  };

  usePrompt(t('forms.navigationConfirmation'), (dirty && withPrompt) || false);

  return {
    dirty,
    errors,
    fieldRef,
    item,
    mode,
    originalItem: persistable.item,
    revert,
    save,
    saving,
    setDirty,
    setItem,
    setWithPrompt,
    valid,
    withPrompt,
    validate,
  };
}

import { DateTime } from 'luxon';
import { t, TFunction } from 'i18next';
import {
  collection,
  doc,
  Firestore,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import {
  MeasurementUnits,
  QuoteStatus,
  QuoteTypeOfBuy,
  TimeZone,
} from '../enums';
import { Cable, Quote, QuoteProduct, User } from '../models';
import { Inventory } from '../types/Inventory';
import { CurrencyFormatter, roundPrice } from './currency';

const FEET_TO_METER_RATIO = 0.304;
const METER_TO_KILOMETER = 0.001;

const convertFeetToMeter = (value = 0) =>
  Number((value * FEET_TO_METER_RATIO).toFixed(2));

const convertMeterToFeet = (value = 0) =>
  Number((value / FEET_TO_METER_RATIO).toFixed(2));

export function getSanitizedExportQuote(
  quote: Quote,
  t: TFunction,
  currencyFormatter?: CurrencyFormatter
) {
  return {
    ...quote,
    quoteNumber: quote.quoteNumber || '',
    projectName:
      quote.typeOfBuy === QuoteTypeOfBuy.InventoryBuy
        ? t('quotes.edit.summary.quoteDetails.typeOfBuy.inventoryBuy')
        : quote.projectName || '',
    typeOfBuy: quote.typeOfBuy
      ? t(`quotes.edit.summary.quoteDetails.typeOfBuy.${quote.typeOfBuy}`)
      : '',
    totalSell: quote.totalCost
      ? currencyFormatter?.format(quote.totalCost) ??
        roundPrice(quote.totalCost)
      : '',
    status: t(`quotes.status.${quote.status}`),
    lastModified: DateTime.fromMillis(quote.lastModified.toMillis())
      .setLocale('en')
      .setZone(TimeZone.EasternTime)
      .toLocaleString(DateTime.DATETIME_MED),
    expirationTime: quote.expirationTime
      ? DateTime.fromMillis(quote.expirationTime.toMillis())
          .setLocale('en')
          .setZone(TimeZone.EasternTime)
          .toLocaleString(DateTime.DATETIME_MED)
      : '',
    companyName: quote.companyName || '',
    customerName: quote.customerName || '',
    agencyName: quote.agencyName || '',
    employeeName: quote.employeeName || '',
    nciProjectId: quote.nciProjectId || '',
  };
}

export function isQuoteProductMarketValid(
  product: QuoteProduct,
  markets: Inventory[]
) {
  return (
    (!!product?.isCSA && markets.includes('csa')) ||
    (!!product?.isUL && markets.includes('ul'))
  );
}

export async function cloneQuote(
  quote: Quote,
  isAdmin: boolean,
  isAgent: boolean,
  currentUser: User,
  firestore: Firestore
) {
  const isRequote = quote.status === QuoteStatus.Expired;
  const newQuote: Quote = {
    ...quote,
    status: isAdmin ? QuoteStatus.InProgress : QuoteStatus.Draft,
    expirationTimeOption: isRequote ? quote.expirationTimeOption : null,
    expirationTime: null,
    dateCreated: Timestamp.now(),
    lastModified: Timestamp.now(),
    quoteNumber: null,
    quotedTime: null,
    totalCost: null,
    createdById: currentUser.id,
    createdByName: currentUser.name || '',
    createdByNCEmployee: currentUser.isNCEmployee,
    employeeId: isAdmin ? currentUser.id : null,
    employeeName: isAdmin ? currentUser.name || '' : null,
    sourceQuoteNumber: isRequote
      ? quote.quoteNumber || quote.sourceQuoteNumber || null
      : null,
    customerNote: null,
    products:
      quote.products?.map(
        ({
          customLeadTime,
          packageLength,
          price,
          purchaseStatus,
          reasonForLost,
          standardLeadTime,
          ...product
        }) => {
          return {
            ...product,
            ...(isRequote ? { price } : {}),
            customLeadTime: null,
          };
        }
      ) ?? [],
  };

  let quoteId = quote.id;
  if (isRequote) {
    const ref = doc(firestore, 'Quotes', quote.id);
    await setDoc(ref, newQuote, { merge: true });
  } else {
    const docRef = doc(collection(firestore, 'Quotes'));
    await setDoc(docRef, { ...newQuote, id: docRef.id });
    quoteId = docRef.id;
  }

  const isInCurrentQuote = window.location.pathname.endsWith(
    `quotes/${quoteId}`
  );

  if (!isRequote || !isInCurrentQuote) {
    window.open(
      `/${isAgent ? 'agent/' : isAdmin ? 'admin/' : ''}quotes/${quoteId}`,
      '_blank'
    );
  }
}

export const convertQuoteProductQuantity = (
  quantity: number,
  units: MeasurementUnits
) =>
  units === MeasurementUnits.Imperial
    ? convertMeterToFeet(quantity)
    : convertFeetToMeter(quantity);

export const convertQuoteProductPackageLength = (
  packageLength: number,
  units: MeasurementUnits
) =>
  units === MeasurementUnits.Imperial
    ? convertMeterToFeet(packageLength)
    : packageLength;

export const convertQuoteProductQuantities = (
  products: QuoteProduct[],
  units: MeasurementUnits
): QuoteProduct[] => {
  if (!products) {
    return [];
  }
  const convertedProducts = products.map((product) => {
    const convertedQuantity = convertQuoteProductQuantity(
      product.quantity ?? 0,
      units
    );
    const convertedMinimumOrderQuantity = convertQuoteProductQuantity(
      product.minimumOrderQuantity ?? 0,
      units
    );
    return {
      ...product,
      quantity: convertedQuantity,
      minimumOrderQuantity: convertedMinimumOrderQuantity,
    };
  });
  return convertedProducts;
};

export const getQuoteProductExtendedPrice = (
  product: QuoteProduct,
  units: MeasurementUnits,
  priceUnits: MeasurementUnits
) => {
  if (product.quantity === undefined || product.price === undefined) {
    return 0;
  }
  let quantity = product.quantity;
  if (units === MeasurementUnits.Imperial) {
    quantity = convertQuoteProductQuantity(quantity, MeasurementUnits.Metric);
  }

  let price = product.price;
  if (priceUnits === MeasurementUnits.Imperial) {
    price = price / FEET_TO_METER_RATIO;
  }

  const discountedPrice = price - price * ((product.discount ?? 0) / 100);

  const result = discountedPrice * quantity * METER_TO_KILOMETER;
  return roundPrice(result);
};

export const getQuantityErrorMessage = (
  quantity: number | undefined,
  item: Cable,
  units: MeasurementUnits
) => {
  if (quantity && item.packageLength) {
    const packageLength = convertQuoteProductPackageLength(
      item.packageLength,
      units
    );
    const mod = quantity % packageLength;
    if (mod !== 0) {
      return t('quotes.edit.products.table.validation.invalidMultiplier', {
        packageLength,
        unit: t(`units.${units}.length`),
        example: quantity - mod + packageLength,
      });
    }
  }
  return null;
};

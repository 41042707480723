import { useTranslation } from 'react-i18next';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, Timestamp } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import { useFirestore } from 'reactfire';
import {
  QuestionDrawer,
  QuoteHeader,
  QuoteProductsTable,
  QuoteSubmit,
  QuoteSummaryDetails,
} from '../components';
import {
  useAuthentication,
  useCompany,
  useCurrentUser,
  useDownloadFiles,
  useForm,
  useNotification,
  usePageTitle,
  useQuote,
  useQuoteDetails,
  useQuoteMarkets,
} from '../hooks';
import {
  CompanyType,
  MeasurementUnits,
  ModificationMode,
  NotificationLevel,
  QuoteStatus,
  QuoteTypeOfBuy,
} from '../enums';
import { getPageTitle } from '../utils/pages';
import { Company, FirebaseFile, Quote as QuoteModel } from '../models';
import getQuoteSchema from '../validation/QuoteValidations';
import { getLanguage } from '../utils/locale';
import { QuoteLayout } from '../layouts';
import { isUsCompany } from '../utils';

const Quote = () => {
  const { t, i18n } = useTranslation();
  const firestore = useFirestore();
  const navigate = useNavigate();
  const { quoteId } = useParams();
  const { addNotification } = useNotification();
  const { isAdmin } = useAuthentication();
  const [showSendQuoteConfirm, setShowSendQuoteConfirm] = useState(false);
  const [company, setCompany] = useState<Company | null>(null);

  const currentUser = useCurrentUser();
  const { item: currentCompany } = useCompany(currentUser.companyRef.id);

  const quote = useQuote(quoteId || '');

  const isNewQuote = quoteId === 'new';

  usePageTitle(
    quoteId === 'new' ? getPageTitle('quote.new') : getPageTitle('quote.edit')
  );

  const { downloadFromFireStorage } = useDownloadFiles();

  const backToQuotes = '/quotes';

  const form = useForm(quote, {
    mode: isNewQuote ? ModificationMode.Add : ModificationMode.Edit,
    defaultValue: {
      ...new QuoteModel(
        currentUser,
        currentCompany?.type === CompanyType.Agency ? currentCompany : null,
        currentCompany?.type === CompanyType.Customer ? currentCompany : null
      ),
    },
    beforeSave: (quote) => {
      if (!isAdmin && currentUser.name && currentUser.email) {
        quote.customerId = currentUser.id;
        quote.customerName = currentUser.name;
        quote.customerEmail = currentUser.email;
      }

      if (isNewQuote) {
        quote.dateCreated = Timestamp.now();
        quote.status = quote.status ?? QuoteStatus.Draft;
      }
      if (!quote.typeOfBuy || quote.typeOfBuy === QuoteTypeOfBuy.InventoryBuy) {
        quote.projectName = null;
        quote.contractorName = null;
        quote.shippingYearMonth = null;
        quote.nciProjectId = null;
      }
      quote.measurementUnits = units;
      quote.priceUnits = priceUnits;
      quote.lastModified = Timestamp.now();

      if (quote.status === QuoteStatus.Submitted) {
        quote.dateSubmitted = Timestamp.now();
      }
    },
    onSaveSuccess: (savedQuote) => {
      addNotification(
        !savedQuote.status || savedQuote.status === QuoteStatus.Draft
          ? t('quotes.edit.drawer.save.successDraft')
          : t('quotes.edit.drawer.save.successRequestSent'),
        NotificationLevel.Success
      );

      if (isNewQuote && savedQuote.status === QuoteStatus.Draft) {
        navigate(`${backToQuotes}/${savedQuote.id}`, {
          replace: true,
        });
      } else if (
        savedQuote.status === QuoteStatus.Submitted &&
        showSendQuoteConfirm
      ) {
        navigateBackToQuotes();
      }
    },
  });

  const { markets } = useQuoteMarkets(form);
  const { isCurrentTab, isReadonly } = useQuoteDetails(form);

  const [units, setUnits] = useState<MeasurementUnits>(
    currentUser.measurementUnits
  );

  const [priceUnits, setPriceUnits] = useState<MeasurementUnits>(
    company && isUsCompany(company)
      ? MeasurementUnits.Imperial
      : MeasurementUnits.Metric
  );
  const { submitSchema } = getQuoteSchema(markets, units);

  useEffect(() => {
    (async () => {
      if (!form.item?.companyId) {
        setCompany(null);
      } else if (company?.id !== form.item?.companyId) {
        const companyRef = doc(firestore, 'Companies', form.item?.companyId);
        const company = (await getDoc(companyRef)).data();
        setCompany(company as Company);
      }
    })();
  }, [company?.id, firestore, form.item?.companyId]);

  useEffect(() => {
    if (form.item?.measurementUnits) {
      setUnits(form.item?.measurementUnits);
    }

    if (form.item?.priceUnits) {
      setPriceUnits(form.item?.priceUnits);
    }
  }, [form.item?.measurementUnits, form.item?.priceUnits]);

  const saveAsDraft = () => {
    form.save(true);
  };

  const sendQuoteRequest = async () => {
    const errors = await form.validate(
      {
        ...form.item,
        status: QuoteStatus.Submitted,
      } as QuoteModel,
      false,
      true,
      submitSchema
    );

    const errorsObj = Object.entries(errors);
    if (errorsObj.length) {
      if (errorsObj[0][1] === t('common.validations.fieldRequired')) {
        addNotification(
          t('quotes.validation.requiredFields'),
          NotificationLevel.Error
        );
      } else {
        addNotification(t(errorsObj[0][1]), NotificationLevel.Error);
      }
    } else {
      setShowSendQuoteConfirm(true);
    }
  };

  const confirmSendQuoteRequest = async () => {
    if (quoteId && form.item) {
      // Need to assign the value directly for the save action to use the updated property
      form.item.status = QuoteStatus.Submitted;
      form.save();
    }
  };

  const cancelSendQuoteRequest = () => {
    setShowSendQuoteConfirm(false);
  };

  const navigateBackToQuotes = () => {
    const isCurrentQuote = isNewQuote || isCurrentTab || !quote.item;

    navigate(backToQuotes + (!isCurrentQuote ? '/history' : ''));
  };

  const downloadQuote = async () => {
    const file = quote.item?.reportFile?.[getLanguage(i18n)] as FirebaseFile;
    downloadFromFireStorage(
      `${file.storageBucket}/${file.storageId}`,
      file.fileName
    );
  };

  const getHeaderElement = () => (
    <QuoteHeader
      form={form}
      isNewQuote={isNewQuote}
      onSave={saveAsDraft}
      navigateBack={navigateBackToQuotes}
      saveButtonText={t('quotes.edit.saveAsDraft')}
      showSaveButton={
        form.dirty && (isNewQuote || form.item?.status === QuoteStatus.Draft)
      }
      units={units}
      setUnits={setUnits}
    />
  );
  const getBodyElement = () => (
    <>
      <QuoteSummaryDetails form={form} isReadonly={isReadonly} />
      <QuoteProductsTable
        company={company}
        form={form}
        units={units}
        priceUnits={priceUnits}
        setUnits={setUnits}
      />
    </>
  );
  const getFooterElement = () => (
    <QuoteSubmit
      company={company}
      form={form}
      units={units}
      priceUnits={priceUnits}
      submitQuote={sendQuoteRequest}
      downloadQuote={downloadQuote}
    />
  );

  return (
    <>
      <QuoteLayout
        headerElement={getHeaderElement()}
        bodyElement={getBodyElement()}
        footerElement={getFooterElement()}
      />
      <QuestionDrawer
        open={showSendQuoteConfirm}
        icon={<RequestQuoteIcon sx={{ color: 'secondary.main' }} />}
        message={t('quotes.edit.summary.submitQuoteRequestDescription')}
        onConfirm={confirmSendQuoteRequest}
        onCancel={cancelSendQuoteRequest}
        title={t('quotes.edit.summary.submitQuoteRequestButtonLabel')}
      />
    </>
  );
};

export default Quote;

import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFirestore } from 'reactfire';
import { sumBy } from 'lodash';
import { useMemo } from 'react';
import { ContrastButtonMain } from '../ContrastButton/ContrastButton.component';
import { Show } from '../Show/Show.component';
import {
  useAuthentication,
  useCurrentUser,
  useQuoteDetails,
} from '../../hooks';
import { QuoteStatus } from '../../enums';
import { cloneQuote, getQuoteProductExtendedPrice } from '../../utils/quote';
import { FileDownloadOutput } from '../FileDownloadOutput/FileDownloadOutput.component';
import { getCompanyLocation } from '../../utils/company';
import { CurrencyFormatter } from '../../utils';
import { getLanguage } from '../../utils/locale';
import { QuoteSubmitProps } from './QuoteSubmit.props';
import { BoxStyles, ButtonStyles, TotalCostStack } from './QuoteSubmit.styles';

export const QuoteSubmit = ({
  company,
  form,
  downloadQuote,
  previewQuote,
  submitQuote,
  units,
  priceUnits,
}: QuoteSubmitProps) => {
  const { t, i18n } = useTranslation();
  const firestore = useFirestore();
  const { isAdmin, isAgent, isCustomer } = useAuthentication();
  const currentUser = useCurrentUser();

  const {
    canSubmit: showSubmit,
    quoteIsPriced,
    isReadonly,
  } = useQuoteDetails(form);

  const companyLocation = getCompanyLocation(company);
  const currencyFormatter = useMemo(
    () => new CurrencyFormatter(companyLocation, Number.MAX_VALUE),
    [companyLocation]
  );

  const cloneQuoteItem = async () => {
    if (form.item) {
      await cloneQuote(form.item, isAdmin, isAgent, currentUser, firestore);
    }
  };

  const showClone = isAdmin && quoteIsPriced;
  const showDownload = Boolean(
    form.item?.reportFile?.[getLanguage(i18n)] && !showSubmit
  );
  const showTotalCost = isAdmin && !isReadonly;

  const totalQuoteValue = useMemo(
    () =>
      currencyFormatter.format(
        sumBy(form.item?.products, (p) =>
          getQuoteProductExtendedPrice(p, units, priceUnits)
        )
      ),
    [form.item?.products, units, currencyFormatter, priceUnits]
  );

  return (
    <Box
      sx={BoxStyles(
        showClone || showSubmit || showDownload,
        showTotalCost,
        isCustomer
      )}
    >
      {showTotalCost && (
        <TotalCostStack>
          <Typography variant="h6">
            {t('quotes.edit.summary.quoteDetails.totalCost')}
          </Typography>
          <Typography variant="h2">
            {totalQuoteValue.toLocaleString()}
          </Typography>
        </TotalCostStack>
      )}
      <Show if={showClone}>
        <Button onClick={cloneQuoteItem} variant="outlined">
          {t(
            `quotes.${
              form.item?.status === QuoteStatus.Expired ? 'requote' : 'clone'
            }Quote`
          )}
        </Button>
      </Show>

      <Show if={showSubmit && isAdmin}>
        <Button
          variant="contained"
          onClick={async () => await previewQuote?.()}
        >
          {t('quotes.edit.summary.previewQuoteButtonLabel')}
        </Button>
      </Show>
      <Show if={showSubmit}>
        <ContrastButtonMain
          onClick={async () => await submitQuote()}
          variant="contained"
          sx={ButtonStyles}
        >
          {t(
            `quotes.edit.summary.${
              isAdmin
                ? 'sendPricedQuoteButtonLabel'
                : 'submitQuoteRequestButtonLabel'
            }`
          )}
        </ContrastButtonMain>
      </Show>
      <Show if={showDownload}>
        <FileDownloadOutput
          children={t('quotes.edit.downloadQuote')}
          onClick={async () => await downloadQuote()}
          uppercase
        />
      </Show>
    </Box>
  );
};

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DefaultValue,
  Language,
  MeasurementUnits,
  QuoteStatus,
} from '../enums';
import { Company, Quote, QuoteProduct } from '../models';
import { CurrencyFormatter } from '../utils';
import { UnquotedQuoteStatuses } from '../mappings';
import { NullableOutput } from '../tables/components/NullableOutput';
import { Formable, Nullable } from '../types';
import {
  convertQuoteProductQuantity,
  getQuoteProductExtendedPrice,
} from '../utils/quote';
import { getCompanyLocation } from '../utils/company';
import { usePortalSettings } from './usePortalSettings';
import { useCurrentUser } from './useCurrentUser';
import { useFormFieldValue } from './useFormFieldValue';

export const METER_TO_KILOMETER = 0.001;

export const useQuoteFields = (
  form: Formable<Quote>,
  company: Nullable<Company> = null
) => {
  const { item: portalSettings } = usePortalSettings();
  const currentUser = useCurrentUser();
  const { t } = useTranslation();

  const companyLocation = useMemo(() => getCompanyLocation(company), [company]);

  const { value: quoteUnits } = useFormFieldValue<Quote, MeasurementUnits>(
    form,
    'measurementUnits'
  );

  const currencyFormatter = useMemo(
    () => new CurrencyFormatter(companyLocation, Number.MAX_VALUE),
    [companyLocation]
  );

  function getNetPrice(row: QuoteProduct) {
    const price = row.price ?? 0;

    if (!row.discount) {
      return currencyFormatter.format(price);
    }

    return currencyFormatter.format(price - price * (row.discount / 100));
  }

  function getExtendedPrice(
    row: QuoteProduct,
    units: MeasurementUnits,
    priceUnits: MeasurementUnits
  ) {
    return currencyFormatter.format(
      getQuoteProductExtendedPrice(row, units, priceUnits)
    );
  }

  function getLeadTime(row: QuoteProduct, quoteStatus: QuoteStatus) {
    if (!row.productType) {
      return DefaultValue.Dash;
    }

    if (!quoteStatus || UnquotedQuoteStatuses.includes(quoteStatus)) {
      return (
        portalSettings?.productTypeLeadTimes?.[row.productType][
          currentUser.language || Language.English
        ] ?? DefaultValue.Dash
      );
    }
    return (
      row.standardLeadTime?.[currentUser.language || Language.English] ??
      DefaultValue.Dash
    );
  }

  function getDescription(row: QuoteProduct) {
    return `${String(row.description || DefaultValue.Dash)}`;
  }
  function getColors(row: QuoteProduct) {
    return `${String(row.colors || null)}`;
  }

  function getTotalCost(quote: Quote) {
    return currencyFormatter.format(quote.totalCost ?? 0);
  }

  function getQuantity(
    row: QuoteProduct,
    displayUnits: MeasurementUnits,
    convert = true
  ) {
    const quantity = convert
      ? convertQuantity(row.quantity ?? 0, displayUnits)
      : row.quantity;
    return `${quantity}${t(`units.${displayUnits}.length`)}`;
  }

  const getPrice = (
    row: QuoteProduct,
    quoteIsPriced: boolean,
    priceUnits: MeasurementUnits
  ) =>
    NullableOutput<QuoteProduct>(
      row,
      'price',
      ({ price }) =>
        `${currencyFormatter.format(price || 0)} ${
          priceUnits === MeasurementUnits.Imperial ? '/1000ft' : '/km'
        }`,
      true,
      !quoteIsPriced
    );

  const getMinimumOrderQuantity = (
    row: QuoteProduct,
    quoteIsPriced: boolean,
    displayUnits: MeasurementUnits
  ) =>
    NullableOutput<QuoteProduct>(
      row,
      'minimumOrderQuantity',
      ({ minimumOrderQuantity }) =>
        `${convertQuantity(minimumOrderQuantity ?? 0, displayUnits)}${t(
          `units.${displayUnits}.length`
        )}`,
      true,
      !quoteIsPriced
    );

  const getDiscount = (row: QuoteProduct, quoteIsPriced: boolean) =>
    NullableOutput<QuoteProduct>(
      row,
      'discount',
      ({ discount }) => `${discount} %`,
      true,
      !quoteIsPriced
    );

  const convertQuantity = (
    quantity: number,
    displayUnits: MeasurementUnits
  ) => {
    if (displayUnits !== quoteUnits) {
      return convertQuoteProductQuantity(quantity, displayUnits);
    }
    return quantity;
  };

  return {
    getDescription,
    getDiscount,
    getExtendedPrice,
    getLeadTime,
    getMinimumOrderQuantity,
    getNetPrice,
    getPrice,
    getQuantity,
    getTotalCost,
    getColors,
  };
};

import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Grid,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useTranslation } from 'react-i18next';
import { DataTable, DataTableColumn } from '../../tables';
import { FilterOption, ProductType } from '../../enums';
import notEmpty from '../../utils/notEmpty';
import { Filter } from '../../types';
import { Cable } from '../../models';
import { QuoteProductQuantity } from '../QuoteProductsTable/Components';
import { getQuantityErrorMessage } from '../../utils/quote';
import { useQuotableCables } from '../../hooks';
import { CatalogTableProps } from './CatalogTable.props';
import * as Sx from './CatalogTable.styles';

export const CatalogTable = ({
  form,
  type,
  filters,
  onAddProduct,
  onUpdateProduct,
  setFilters,
  sorting,
  units,
}: CatalogTableProps) => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const [quantities, setQuantities] = useState<{ [key: string]: number }>({});

  const queryFilters = useMemo(() => {
    if (!filters) return undefined;

    const filterList = [];

    filterList.push(`${FilterOption.StandardType}=${type}`);

    if (!filters.searchByCable && filters.partNumber) {
      filterList.push(`${FilterOption.CableId}=${filters.partNumber}`);
    } else {
      const {
        productType,
        productSubType,
        material,
        gauge,
        numberOfConductors,
      } = filters;
      let { voltage } = filters;
      if (voltage === 'N/A') {
        voltage = -1;
      }

      filterList.push(
        productType && `${FilterOption.ProductType}=${productType}`
      );
      filterList.push(
        productSubType && `${FilterOption.ProductSubType}=${productSubType}`
      );
      filterList.push(material && `${FilterOption.MaterialType}=${material}`);
      filterList.push(voltage && `${FilterOption.Voltage}=${voltage}`);
      filterList.push(
        productType !== ProductType.BareGround &&
          numberOfConductors &&
          `${FilterOption.NumberOfConductors}=${numberOfConductors}`
      );
      filterList.push(gauge && `${FilterOption.Gauge}=${gauge}`);
    }

    return [
      ['filters', 'array-contains', filterList.filter(notEmpty).join(';')],
    ] as Filter[];
  }, [filters, type]);

  const { list: cables, loading } = useQuotableCables({
    filters: queryFilters,
    sorting,
  });

  useEffect(() => {
    if (filters && !filters.searchByCable && !loading && cables) {
      setFilters({
        ...filters,
        productType: cables.length > 0 ? cables[0].productType : null,
      });
    }
    // This component is only rendered if filters is not null, therefore:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.productType, cables, loading]);

  const cableDescription = (description: string, item: Cable) => {
    if (item.colors) {
      return (
        <>
          <div>
            {description} - {item.id}
          </div>
          <div>{item.colors}</div>
        </>
      );
    }

    return (
      <>
        {description} - {item.id}
      </>
    );
  };

  return (
    <DataTable
      data={queryFilters && !loading ? cables : []}
      loading={loading}
      sorting={sorting}
      tableStyles={Sx.tableStyles}
      tableHeaderStyles={Sx.tableHeaderStyles}
      tableBodyStyles={Sx.tableBodyStyles}
      disableActiveItem
    >
      <DataTableColumn
        key="description-column"
        property="description"
        title={t('catalog.fields.partNumber')}
        mobileOrder={0}
        mobileWidth="60%"
        mobileHeight={100}
        output={(description, item: Cable) =>
          cableDescription(description, item)
        }
        otherStyling={{
          wordBreak: 'break-word',
          px: {
            xs: 1.5,
            md: 2,
          },
          pt: {
            xs: '0.625rem',
          },
        }}
      />
      {!!form?.item && (
        <DataTableColumn
          mobileOrder={1}
          mobileWidth="40%"
          mobileHeight={100}
          property="id"
          disableSort
          title={`${t('quotes.edit.products.table.labels.quantity')}*`}
          otherStyling={{
            backgroundColor: 'white',
            my: {
              xs: 'auto',
            },
            px: {
              xs: 0,
              md: 2,
            },
          }}
          output={(_, item: Cable) => {
            const addedProduct = form?.item?.products?.find(
              (p) => p.partNumber === item.id
            );

            return (
              <QuoteProductQuantity
                showError={false}
                quantity={addedProduct?.quantity ?? (quantities[item.id] || 0)}
                onChange={(val) => {
                  if (addedProduct) {
                    onUpdateProduct(item, val);
                  } else {
                    setQuantities((prev) => {
                      return { ...prev, [item.id]: val };
                    });
                  }
                }}
                inputAdornment={t(`units.${units}.length`)}
                width="140px"
                numberValidation={(val) =>
                  getQuantityErrorMessage(val, item, units)
                }
              />
            );
          }}
        />
      )}
      {!!form?.item && (
        <DataTableColumn
          key="add-column"
          property="action"
          title=""
          disableSort
          mobileWidth="100%"
          mobileHeight={50}
          mobileOrder={2}
          textAlign="right"
          mobileTextAlign="center"
          output={(_, item: Cable) => {
            if (!form?.item?.products?.find((p) => p.partNumber === item.id)) {
              return (
                <Tooltip
                  placement="top"
                  title={
                    !quantities[item.id]
                      ? t('catalog.searchDialog.requiredQuantity')
                      : undefined
                  }
                >
                  <span
                    style={{
                      display: 'inline-block',
                      ...(isMobile ? { width: '100%' } : {}),
                    }}
                  >
                    <Button
                      fullWidth
                      disabled={
                        !quantities[item.id] ||
                        !!getQuantityErrorMessage(
                          quantities[item.id],
                          item,
                          units
                        )
                      }
                      variant="outlined"
                      color="primary"
                      sx={{ display: 'inline-block' }}
                      onClick={() =>
                        onAddProduct(item, quantities[item.id] || 0)
                      }
                    >
                      {t('catalog.searchDialog.addButton')}
                    </Button>
                  </span>
                </Tooltip>
              );
            } else {
              return (
                <Grid
                  container
                  alignItems="center"
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Typography variant="body2" color="secondary" mr={1}>
                    {t('catalog.searchDialog.productAdded')}{' '}
                  </Typography>
                  <CheckCircleRoundedIcon color="secondary" />
                </Grid>
              );
            }
          }}
        />
      )}
    </DataTable>
  );
};
